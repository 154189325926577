.blockersWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 140px;
}

.blockerMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  padding: 50px 100px;
  background-color: white;
  border-radius: 3px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  color: #000;
  background-color: #d4dbff;
  border-radius: 100%;
}

.title {
  margin-top: 34px;
  font-family: 'Barlow', sans-serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-align: center;
}

.content {
  margin-top: 16px;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-align: center;
  color: #8a909e;
}

.content strong {
  font-weight: 800;
  color: #000;
}
