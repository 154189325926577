/* [Global Styling] */

html {
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #e5e5e5;
  text-size-adjust: auto;
  font-size: 25px;
  /* ADJUST for Use of global REM units */
  scroll-behavior: smooth;
  font-family: Helvetica;
  background-color: white;
}

.App {
  height: 100vh;
}

/* ================================== */

/* ==== [Form Section Elements] ==== */

/* [Header] */
#new-ticket-screen {
  background-color: #031331;
}

#idg-logo-container {
  padding: 6% 0 3% 0;
  text-align: center;
}

.form-container {
  background-color: #f4f4f4;
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* [Sub-Header] */
.form-sub-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw 0 5vw;
}

#form-title {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.99rem;
}

#scan-license-button {
  height: 4vh;
}

/* [Form Container] */
.form {
  background-color: #fff;
  border-radius: 15px;
  margin: 0 5.13% 0 5.13%;
  padding: 0 4vw 0 4vw;
}

/* [Form Fields] */
.form-field label {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

p.field {
  font-family: 'Helvetica Neue';
  font-size: 0.7rem;
  font-weight: 700;
  color: #444955;
  margin-bottom: 0.25rem;
}

p.field::after {
  /* Asterisk after form field labels */
  content: '  \002A';
  color: #3c5bff;
}

.inputLabels {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  /* align-self: flex-start; */
}

.checkboxContainer {
  display: flex;
  gap: 20px;

  .checkboxComponent {
    padding: 10px 30px;
    border: 2px solid #c3cbdd;
    border-radius: 30px;
    color: #212121;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    display: flex;
    gap: 8px;
  }
}

label input,
label select {
  height: 1.9rem;
  width: 98%;
  border-radius: 8px;
  font-size: 0.66rem;
  padding-left: 0.4rem;
  border: 1px solid #c3cbdd;
  background-color: #fcfdff;
  letter-spacing: 0.025rem;
}

.radio-label,
#p-delivery {
  font-family: 'Helvetica Neue';
  font-size: 0.7rem;
  color: #8a909e;
}

/* [Gender Radio Buttons] */
#gender-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#gender-label label {
  align-self: flex-end;
}

.radio-button-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
}

.radio-button {
  margin-right: 1rem;
}

/* [birthdate & age container] */
.birthdate-age-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.date-picker-container {
  display: flex;
  align-items: center;
}

.calendar-icon-container {
  position: absolute;
  right: 0;
  margin-right: 40%;
  color: black;
  z-index: 1;
  pointer-events: none;
}

.css-1dozdou {
  overflow: visible !important;
  padding-left: 0 !important;
}

.css-l0iinn {
  overflow: visible !important;
  max-height: 0 !important;
  font-size: 0.72rem !important;
}

#dateOfBirth-container {
  flex-basis: 63%;
}

#age-container {
  flex-basis: 28%;
  padding-right: 0.25rem;
}

#age {
  margin-left: -0.65vw;
}

/* Text Input Button for Portrait Calendar */
.css-1ehjhs2 {
  display: none !important;
}

/*  */

/* Entire Calendar Picker Window */
.css-3dah0e-MuiModal-root-MuiDialog-root .MuiDialog-paper {
  padding: 0 2% !important;
}

/* Text Input Button for Landscape Calendar */
.css-1tbq6mc-MuiPickersToolbar-root-MuiDatePickerToolbar-root .MuiPickersToolbar-penIconButton {
  display: none !important;
}

/* Text Input Button for Portrait Calendar */
.MuiButtonBase-root
  .MuiIconButton-root
  .MuiIconButton-colorInherit
  .MuiIconButton-sizeMedium
  .MuiPickersToolbar-penIconButton
  .css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
  display: none !important;
}

/* Text Input Button in Year Selection window */
.css-j88s13-MuiPickersToolbar-root-MuiDatePickerToolbar-root .MuiPickersToolbar-penIconButton {
  display: none !important;
  padding: 0 4% !important;
}

/* Month and Year larger display on Date Selection Window */
.css-j88s13-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  margin-left: 5px !important;
}

/* Month and Year smaller display on Date Selection Window */
.css-1v994a0 {
  margin-right: 0 !important;
  padding-left: 30px !important;
  /* TESTING */
}

/* Year Dropdown Button */
.css-7kykdr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
  margin-right: -10px !important;
}

/* Year Selection Grid */
.MuiYearPicker-root .css-x1wgng-MuiYearPicker-root {
  margin: 0 !important;
}

/* Blue Background in Year Selection and Year*/
.css-m1gykc {
  line-height: 1.45 !important;
  width: 80px !important;
  border-radius: 28px !important;
  font-size: 0.85rem !important;
  /* Year Selection Font Size */
  /* formerValue: 1rem */
}

/* Date Displayed in Year Selection Window */
.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
  font-size: 1.75rem !important;
  /* formerValue: 2.1rem */
}

/* 'OK' & 'CANCEL' buttons on lower right of datePicker window */
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  display: none !important;
}

.css-14b29qc {
  display: none !important;
}

/* ======================================================================================== */

/* [Delivery Checkbox] */
#delivery-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-top: .4rem; */
}

#delivery {
  height: auto;
  width: auto;
  margin-right: 0.5rem;
}

#p-delivery {
  font-weight: 400;
  margin-bottom: 0.7rem;
}

#p-delivery::after,
#acct-validator-email::after,
#acct-validator-driversLicenseNumber::after,
#acct-validator-reasons::after,
#age-field::after {
  content: '';
}

#idg-logo-rect {
  width: 30%;
}

/* ================================== */

/* ===== [Acct Validator Form] ===== */
.existing-acct-validator-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  /* text-align: center; */
}

.existing-acct-validator-section h2 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.3rem;
}

#sub-header-instruction {
  text-align: center;
  font-family: 'Helvetica Neue';
  font-size: 0.66rem;
  margin: 2% 6.5% 0 6.5%;
}

select#reasonForVisit,
#driversLicenseNumber-validator-input,
#email-validator-input {
  border: 2px solid #c3cbdd;
  height: 1.7rem;
}

#preferredLanguage,
select#reasonForVisit {
  height: 1.94rem;
  padding-left: 0.25rem;
}

select#reasonForVisit {
  width: 100%;
}

#preferredLanguage {
  width: 99.6%;
}

/* ====== [Dropdown Menu Items] ====== */
/* .dropdown-menu {
    width: 100%;
    height: 100%;
  } */
/* ================================== */

/* ====== [Confirmation Ticket Screen] ====== */
#confirmed-ticket-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(80%);
  /* TESTING */
  text-align: center;
  margin: 0 6%;
}

#check-ticket-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 6%;
}

#confirmed-ticket-page h3 {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.9rem;
  white-space: nowrap;
}

#confirmed-ticket-page p {
  font-family: 'Helvetica Neue';
  color: #8a909e;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
}

#confirmed-ticket-page h3 {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 0.9rem;
  white-space: nowrap;
}

#confirmed-ticket-page p {
  font-family: 'Helvetica Neue';
  color: #8a909e;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
}

.ticket-icon-container {
  position: relative;
  background-image: url('./images/ticket-bg.png');
}

.ticket-icon-container .ticket-img {
  margin-left: 18px;
}

.ticket-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 190px;
  width: 100%;
  height: 12%;
  background-image: url('./images/ticket-bg.png');
}

.ticket-customer-name {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #8a909e;
  margin-right: 16px;
}

.ticket-message {
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #21252c;
}

.ticket-status {
  font-weight: 700;
  font-size: 22px;
  font-family: 'Roboto';
  color: #3c5bff;
}

.ticket-idg-number {
  font-weight: 700;
  font-size: 16px;
  font-family: 'Roboto';
  color: #3c5bff;
}

.ticket-information-container {
  padding-right: 50px;
  padding-top: 35px;
}

.ticket-information-container .info {
  display: flex;
  margin-bottom: 1.2rem;
  font-family: 'Roboto';
  font-size: 16px;
}

.info .info-title {
  font-weight: 600;
  color: #21252c;
}

.info .info-description {
  font-weight: 500;
  color: #8a909e;
  margin-top: 6px;
}

.ticket-deactivated {
  font-size: 16px;
  font-weight: 400;
  color: #8a909e;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.button-original {
  background-color: #3c5bff;
  color: #fff;
  height: 1.8rem;
  width: 4rem;
  font-family: Roboto;
  font-weight: 500;
  font-size: 0.7rem;
  letter-spacing: 0.2px;
  border-radius: 8px;
  border-style: none;
}

div.circle-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #d4dbff;
  height: 88px;
  width: 88px;
  top: -70px;
  right: -40px;
  border-radius: 100%;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  color: #2649ff;
}

/* ========================================== */

/* ====== [Buttons] ====== */
#scan-license-button {
  color: #3c5bff;
  background-color: rgba(212, 219, 255, 0.45);
  font-family: 'Helvetica Neue';
  font-weight: 700;
  height: 1.64rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #d4dbffcc;
  border-radius: 6px;
  visibility: hidden;
  /* REMOVE WHEN READY FOR USE */
}

#scan-license-button svg {
  color: #3c5bff;
  padding-right: 0.28rem;
  font-size: 0.75rem;
}

#submit-button-container {
  text-align: end;
  padding-bottom: 1.25rem;
}

#submit-button,
#continue-button {
  background-color: #3c5bff;
  color: #fff;
  height: 1.8rem;
  width: 4rem;
  font-family: Roboto;
  font-weight: 500;
  font-size: 0.7rem;
  letter-spacing: 0.2px;
  border-radius: 8px;
}

#continue-button-container {
  padding: 8% 0 3% 0;
}

#continue-button {
  width: 100%;
  border-radius: 30px;
}

/* ================================== */

/* ======= [Error Messaging] ======= */
.errorMessage {
  font-family: 'Roboto';
  color: #e73846;
  font-size: 0.58rem;
  margin: 0;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  align-self: flex-end;
  padding-bottom: 0.3rem;
}

/* ========= [ Media Query 1000px Width] ========== */
@media screen and (max-width: 1000px) {
  select#reasonForVisit {
    width: 100%;
  }

  #preferredLanguage {
    width: 100%;
  }
}

/* ========= [ Media Query 800px Width] ========== */
@media screen and (max-width: 800px) {
  select#reasonForVisit {
    width: 100.7%;
  }

  #preferredLanguage {
    width: 100.7%;
  }
}

/* ========= [ Media Query 700px Width] ========== */
@media screen and (max-width: 700px) {
  select#reasonForVisit {
    width: 100.9%;
  }

  #preferredLanguage {
    width: 100.9%;
  }
}

/* ========= [ Media Query 600px Width] ========== */
@media screen and (max-width: 600px) {
  select#reasonForVisit {
    width: 101.75%;
  }
}

/* ========= [ Media Query 500px Width] ========== */
@media screen and (max-width: 500px) {
  select#reasonForVisit {
    width: 103%;
  }
}

/* ========= [ Media Query 400px Width] ========== */
@media screen and (max-width: 400px) {
  select#reasonForVisit {
    width: 103.8%;
  }
}

/* ========= [ Media Query 360px Width] ========== */
@media screen and (max-width: 360px) {
  #confirmed-ticket-page h3 {
    font-size: 0.8rem;
  }

  #confirmed-ticket-page p {
    font-size: 0.65rem;
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.7;
  position: fixed;
  top: 0;
  z-index: 10;
}

.loader {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 3px solid #2649ff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2649ff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ::backdrop {
  background-color: white;
} */
